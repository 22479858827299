/** @format */

import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import CancelCreateTicketModal from './cancel';
import SuccessCreateTicket from './success';
import ErrorCreateTicket from './error';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button';
import useAuth from 'src/hooks/useAuth';
import SelectComponent from 'src/components/select';
import { useDispatch, useSelector } from 'src/store';
import { clearTicketError, createTicket, getTickets } from 'src/slices/tickets';
import { DeleteIcon, UploadIcon } from 'src/components/icons';
import fileSize from 'filesize';
import { getFileBase64 } from 'src/utils/helper';
import { IS_PRODUCTION, VALIDATE_REGEX } from 'src/constants';

const initPostData = {
  title: null,
  contactName: null,
  contactEmail: null,
  requestType: null,
  dealerAssociation: null,
  impact: null,
  urgency: null,
  typeOfProblem: null,
  category: null,
  endCustomerAccount: null,
  feature: null,
  affectedEmailAddress: null,
  affectedVehicle: null,
  affectedDriver: null,
  model: null,
  softwareVersion: null,
  deviceserialnumber: null,
  vehicleRegistrationNumber: null,
  type: null,
  companyCardNumber: null,
  description: null,
  attachment: null,
  attachmentFileName: null,
  dateOfOccurence: null,
  timeOfOccurence: null,
  project: null,
  region: null,
  ava_endcustomer: null,
  ava_endcustomercontact: null
};
const initImpactData = [
  { value: 'Significant', name: 'Significant' },
  { value: 'Moderate', name: 'Moderate' },
  { value: 'Minor', name: 'Minor' }
];
const initUrgencyData = [
  { value: 'High', name: 'High' },
  { value: 'Medium', name: 'Medium' },
  { value: 'Low', name: 'Low' }
];
const regionData = [
  { value: 'Europe', name: 'Europe' },
  { value: 'North America', name: 'North America' },
  { value: 'Japan', name: 'Japan' }
];
const mapOnDemandProjectMapping = {
  'maps-on-demand-customer-mitsubishi': 'Mitsubishi',
  'maps-on-demand-customer-renault': 'Renault',
  'maps-on-demand-customer-nissan-isa': 'Nissan ISA',
  'maps-on-demand-customer-nissan-tiny': 'Nissan Tiny'
};

export default function CreateTicketForMoDModal({ open, onClose }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isOtherPerson, setIsOtherPerson] = React.useState(false);
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [postData, setPostData] = React.useState(initPostData);
  const [modCategoryData, setModCategoryData] = React.useState([]);
  const [validations, setValidations] = React.useState({});
  const [files, setFiles] = React.useState([]);
  const [fileKey, setFileKey] = React.useState(Math.random().toString(36));
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.users);
  const { updateTicketLoading, error: errorTicket } = useSelector(state => state.tickets);
  const [impactData, setImpactData] = React.useState(initImpactData);
  const [urgencyData, setUrgencyData] = React.useState(initUrgencyData);

  React.useEffect(() => {
    if (user && userData) {
      restartProcess();
      if (user.roles?.includes('admin')) setIsAdmin(true);
    }
  }, [open, user, userData]);
  React.useEffect(() => {
    if (success && !updateTicketLoading) {
      setPostData(initPostData);
    }
  }, [success, updateTicketLoading]);
  // Set component data dropdown
  React.useEffect(() => {
    switch (postData.requestType) {
      case 'Task':
      case 'Incident':
      default:
        setModCategoryData([
          { value: 'Account Creation', name: 'Account Creation' },
          { value: 'API Status', name: 'API Status' },
          { value: 'Data Availability', name: 'Data Availability' },
          { value: 'Data Connectivity Issue', name: 'Data Connectivity Issue' },
          { value: 'EhProvider (embedded)', name: 'EhProvider (embedded)' },
          { value: 'License Issues', name: 'License Issues' },
          { value: 'Map Issue', name: 'Map Issue' }
        ]);
        break;
    }
  }, [postData.requestType]);
  // Set region Data
  React.useEffect(() => {
    if (userData.scenario && !userData.scenario.includes('nissan')) {
      setPostData(_postData => ({ ..._postData, region: 'Europe' }));
    }
  }, [userData]);
  // Change isOtherPerson checkbox
  React.useEffect(() => {
    setValidations({});
    if (isOtherPerson) {
      setPostData(newPostData => ({
        ...newPostData,
        endCustomerAccount: '',
        contactName: '',
        contactEmail: ''
      }));
    } else if (userData) {
      setPostData(newPostData => ({
        ...newPostData,
        endCustomerAccount: userData.company,
        contactName: userData.firstName + ' ' + userData.lastName,
        contactEmail: userData.email
      }));
    }
  }, [isOtherPerson]);

  const restartProcess = React.useCallback(() => {
    setPostData({
      ...initPostData,
      customerId: user.company || 'B2C dummy',
      endCustomerAccount: user.firstName + ' ' + user.lastName,
      accountName: user.firstName + ' ' + user.lastName,
      zip: user.address?.postalCode || userData.address?.postalCode || '',
      contactName: user.firstName + ' ' + user.lastName,
      contactEmail: user.email,
      affectedEmailAddress: user.email,
      ava_endcustomer: user.firstName + ' ' + user.lastName,
      ava_endcustomercontact: user.email,
      project: user.scenario
        ? mapOnDemandProjectMapping[user.scenario]
          ? mapOnDemandProjectMapping[user.scenario]
          : null
        : null,
      region: userData.scenario && !userData.scenario.includes('nissan') ? 'Europe' : null
    });
    setFiles([]);
    setFileKey(Math.random().toString(36));
    setImpactData([...initImpactData]);
    setUrgencyData([...initUrgencyData]);
    setValidations({});
  }, [user, userData]);
  const validate = React.useCallback(() => {
    const _validations = {
      ...validations,
      requestType: !postData.requestType,
      modCategory: !postData.modCategory,
      title: !postData.title,
      description: !postData.description,
      eHorizonVersion: postData.requestType === 'Incident' ? !postData.eHorizonVersion : false,
      impact: !postData.impact,
      urgency: !postData.urgency,
      contactName: !postData.contactName,
      region: !postData.region,
      contactEmail: !postData.contactEmail || !VALIDATE_REGEX.email.test(postData.contactEmail)
    };
    setValidations(_validations);
    return _validations;
  }, [postData, validations]);

  const updatePostData = (name, value) => {
    if (name === 'requestType') {
      if (value === 'Task') {
        setPostData(newPostData => ({
          ...newPostData,
          [name]: value,
          impact: 'Minor',
          region: userData.scenario && !userData.scenario.includes('nissan') ? 'Europe' : null
        }));
        setImpactData([...initImpactData]);
        setUrgencyData([...initUrgencyData]);
      } else {
        setPostData(newPostData => ({
          ...newPostData,
          [name]: value,
          impact: null,
          region: userData.scenario && !userData.scenario.includes('nissan') ? 'Europe' : null
        }));
        setImpactData([{ value: 'Extensive', name: 'Extensive' }, ...initImpactData]);
        setUrgencyData([{ value: 'Critical', name: 'Critical' }, ...initUrgencyData]);
      }
    } else {
      setPostData(newPostData => ({ ...newPostData, [name]: value }));
    }
    switch (name) {
      case 'requestType':
      case 'modCategory':
      case 'contactName':
      case 'title':
      case 'description':
      case 'impact':
      case 'region':
      case 'urgency':
        setValidations(_validations => ({ ..._validations, [name]: !value }));
        break;
      case 'eHorizonVersion':
        setValidations(_validations => ({
          ..._validations,
          [name]: postData.requestType === 'Incident' ? !value : false
        }));
        break;
      case 'contactEmail':
        setValidations(_validations => ({ ..._validations, [name]: !value || !VALIDATE_REGEX.email.test(value) }));
        break;
      default:
        break;
    }
  };

  const closeNewTicket = () => {
    if (!updateTicketLoading) {
      onClose(false);
      setCancelModalOpen(false);
      dispatch(clearTicketError());

      // avoid flash of layout
      setTimeout(() => {
        setPostData(initPostData);
        setError(false);
        setSuccess(false);
        setFiles([]);
      }, 300);
    }
  };
  const handleClose = () => {
    if (!updateTicketLoading) {
      if (success) closeNewTicket();
      else setCancelModalOpen(true);
    }
  };
  const handleSubmit = async () => {
    const _validations = validate();
    if (Object.values(_validations).every(item => item === false)) {
      const smy_incidentdateofoccurence = postData.dateOfOccurence
        ? postData.dateOfOccurence + (postData.timeOfOccurence ? `T${postData.timeOfOccurence}:00Z` : 'T00:00:00Z')
        : null;
      const createTicketPostData = {
        caseType: postData.requestType,
        title: IS_PRODUCTION ? postData.title : `(TEST) ${postData.title}`,
        description: postData.description,
        customerid: postData.customerId,
        accountName: postData.endCustomerAccount,
        zip: postData.zip,
        contactName: postData.contactName || postData.ava_endcustomer,
        contactEmail: postData.contactEmail || postData.ava_endcustomercontact,
        project: postData.project,
        impact: postData.impact,
        urgency: postData.urgency,
        smy_incidentdateofoccurence: smy_incidentdateofoccurence
          ? new Date(smy_incidentdateofoccurence).toISOString()
          : null,
        attachment: postData.attachment,
        attachmentFileName: postData.attachmentFileName,
        ava_endcustomer: isOtherPerson ? postData.ava_endcustomer : null,
        ava_endcustomercontact: isOtherPerson ? postData.ava_endcustomercontact : null,
        eHorizonVersion: postData.eHorizonVersion,
        region: postData.region || null,
        modCategory: postData.modCategory
      };
      await dispatch(createTicket(createTicketPostData));
      setError(false);
      setSuccess(true);

      const Storage = window.localStorage;
      const lang = Storage.i18nextLng || 'en';
      await dispatch(getTickets(lang));
    }
  };

  const handleSelectFiles = async e => {
    if (e.target.files && e.target.files[0]) {
      const { size, name } = e.target.files[0];
      if (size / (1000 * 1000) > 25) {
        const sizePretty = fileSize(size);
        setValidations(_validations => ({
          ..._validations,
          attachment: t('personalData.profile_errors.maxSize', { fileSize: sizePretty, limit: '25 MB' })
        }));
        return;
      }
      setValidations(_validations => ({
        ..._validations,
        attachment: false
      }));
      setFiles(files.concat(Array.from(e.target.files)));
      const attachment = await getFileBase64(e.target.files[0]);
      setPostData({ ...postData, attachmentFileName: name, attachment });
    }
  };
  const removeFile = index => {
    files.splice(index, 1);
    setFiles([...files]);
    setPostData({ ...postData, attachmentFileName: null, attachment: null });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        PaperProps={{
          style: {
            backgroundColor: '#fff',
            borderRadius: 10
          }
        }}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title">
        <DialogActions>
          <IconButton onClick={handleClose} disabled={updateTicketLoading}>
            <CloseIcon style={{ color: '#000' }} />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <div className="create-ticket-body">
            {errorTicket ? (
              <ErrorCreateTicket closeModal={closeNewTicket} />
            ) : error ? (
              <ErrorCreateTicket closeModal={closeNewTicket} />
            ) : success ? (
              <SuccessCreateTicket closeModal={closeNewTicket} />
            ) : (
              <>
                <Typography className="create-ticket-title">{t('Tickets.newTicket')}</Typography>
                <div className="contact-person-heading">{t('Tickets.contactPerson')}</div>
                {user && (
                  <div className="contact-person">
                    <div className="contact-person-left">
                      <p className="contact-name">
                        {user.firstName} {user.lastName}
                      </p>
                      <p className="contact-email">{user.email}</p>
                      <p className="contact-tel">{user.address?.phoneNumber || ''}</p>
                    </div>
                    <div className="contact-person-right">
                      <p className="contact-address">{user.company}</p>
                      {user.address && (
                        <>
                          <p className="contact-street">
                            {user.address.street} {user.address.postBox}
                          </p>
                          <p className="contact-city">
                            {user.address.country} {user.address.postalCode} {user.address.city}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className="contact-person-checkbox">
                  {/* TEMPORARY HIDDEN */}
                  {/* <div className="contact-person-checkbox-flex">
                    <input
                      type="checkbox"
                      id="contact-person-checkbox"
                      onChange={() => setIsOtherPerson(!isOtherPerson)}
                      checked={isOtherPerson}
                    />
                    <label htmlFor="contact-person-checkbox">{t('Tickets.checkboxLabelNewTicket')}</label>
                  </div> */}
                  {isOtherPerson && (
                    <Grid container style={{ marginTop: 10 }}>
                      <Grid item xs={12}>
                        <TextField
                          className="create-ticket-text-field"
                          fullWidth
                          id="other-person-name"
                          label={`${t('Tickets.name')} *`}
                          variant="standard"
                          style={{ marginBottom: 10 }}
                          onChange={e => updatePostData('contactName', e.target.value)}
                          value={postData.contactName || ''}
                          error={validations.contactName || null}
                        />
                        <TextField
                          className="create-ticket-text-field"
                          fullWidth
                          id="other-person-email"
                          label={`${t('Tickets.email')} *`}
                          variant="standard"
                          onChange={e => updatePostData('contactEmail', e.target.value)}
                          value={postData.contactEmail || ''}
                          error={validations.contactEmail || null}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid style={{ marginBottom: 10 }}>
                      <SelectComponent
                        label={`${t('Tickets.requestType')} *`}
                        options={[
                          { value: 'Incident', name: 'Report a service issue' },
                          { value: 'Task', name: 'Ask for a service request or a task' }
                          // { value: 'Question (TBC)', name: 'Declare a map content issue' }
                        ]}
                        onChange={e => {
                          updatePostData('requestType', e.target.value);
                        }}
                        value={postData.requestType || ''}
                        error={validations.requestType || null}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid style={{ marginBottom: 10 }}>
                      <SelectComponent
                        label={`${t('Tickets.modCategory')} *`}
                        options={modCategoryData}
                        onChange={e => updatePostData('modCategory', e.target.value)}
                        value={postData.modCategory || ''}
                        error={validations.modCategory || null}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid style={{ marginBottom: 10 }}>
                      <TextField
                        className="create-ticket-text-field"
                        label={`${t('Tickets.title')} *`}
                        onChange={e => updatePostData('title', e.target.value)}
                        value={postData.title || ''}
                        error={validations.title || null}
                        // helperText={postData.title ? `${postData.title.length}/200` : ``}
                        inputProps={{ maxLength: 200 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid style={{ marginBottom: 30 }}>
                    <TextField
                      className="create-ticket-textarea"
                      multiline
                      label={`${t('Tickets.description')} *`}
                      onChange={e => updatePostData('description', e.target.value)}
                      value={postData.description || ''}
                      variant="outlined"
                      minRows={4}
                      error={validations.description || null}
                      // helperText={postData.description ? `${postData.description.length}/100000` : ``}
                      inputProps={{ maxLength: 100000 }}
                    />
                  </Grid>
                </Grid>
                {postData.requestType === 'Incident' && (
                  <Grid container wrap="nowrap" style={{ gap: 24 }}>
                    <Grid item xs={4} style={{ flexBasis: 'calc(33.333333% - 12px)' }}>
                      <Grid style={{ marginBottom: 10 }}>
                        <TextField
                          type="date"
                          className="create-ticket-text-field create-ticket-text-field-date"
                          label={`${t('Tickets.incidentDateOfOccurrence')}`}
                          onChange={e => updatePostData('dateOfOccurence', e.target.value)}
                          value={postData.dateOfOccurence || ''}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={4} style={{ flexBasis: 'calc(33.333333% - 12px)' }}>
                      <Grid style={{ marginBottom: 10 }}>
                        <TextField
                          type="time"
                          className="create-ticket-text-field create-ticket-text-field-date"
                          label={`${t('Tickets.incidentTimeOfOccurrence')}`}
                          onChange={e => updatePostData('timeOfOccurence', e.target.value)}
                          value={postData.timeOfOccurence || ''}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={4} style={{ flexBasis: 'calc(33.333333% - 12px)' }}>
                      <Grid style={{ marginBottom: 10 }}>
                        <TextField
                          className="create-ticket-text-field"
                          label={`${t('Tickets.eHorizonVersion')} *`}
                          onChange={e => updatePostData('eHorizonVersion', e.target.value)}
                          value={postData.eHorizonVersion || ''}
                          error={validations.eHorizonVersion || null}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid container wrap="nowrap" style={{ gap: 24 }}>
                  <Grid item xs={4} style={{ flexBasis: 'calc(33.333333% - 12px)' }}>
                    <Grid style={{ marginBottom: 10 }}>
                      <SelectComponent
                        label={`${t('Tickets.impact')} *`}
                        options={impactData}
                        onChange={e => updatePostData('impact', e.target.value)}
                        value={postData.impact}
                        error={validations.impact || null}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={4} style={{ flexBasis: 'calc(33.333333% - 12px)' }}>
                    <Grid style={{ marginBottom: 10 }}>
                      <SelectComponent
                        label={`${t('Tickets.urgency')} *`}
                        options={urgencyData}
                        onChange={e => updatePostData('urgency', e.target.value)}
                        value={postData.urgency}
                        error={validations.urgency || null}
                      />
                    </Grid>
                  </Grid>
                  {userData.scenario && userData.scenario.includes('nissan') && (
                    <Grid item xs={4} style={{ flexBasis: 'calc(33.333333% - 12px)' }}>
                      <Grid style={{ marginBottom: 10 }}>
                        <SelectComponent
                          label={`${t('Tickets.region')} *`}
                          options={regionData}
                          onChange={e => updatePostData('region', e.target.value)}
                          value={postData.region}
                          error={validations.region || null}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {files.length === 0 && (
                  <Grid container style={{ marginTop: 20 }}>
                    <Grid item xs={12}>
                      <Grid style={{ marginBottom: 10 }}>
                        <Button
                          disabled={updateTicketLoading}
                          noArrow
                          borderOnly
                          additionalClassName="visually-hidden-input">
                          <label htmlFor="attachment">
                            <UploadIcon /> {t('Tickets.attachmentPlaceholder')}
                          </label>
                          <input
                            // accept="image/png, image/gif, image/jpeg, image/jpg"
                            id="attachment"
                            type="file"
                            // multiple
                            onChange={handleSelectFiles}
                            key={fileKey}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {files.length > 0 && (
                  <Grid container style={{ marginTop: 20 }}>
                    <Grid item xs={12}>
                      <Grid style={{ marginBottom: 10 }}>
                        <h4>Selected file</h4>
                        <div className="file-list">
                          {files.map((file, fileIndex) => (
                            <div className="file-item" key={`${fileIndex}-${file.name}-${file.size}`}>
                              {file.name} ({fileSize(file.size)})
                              <button onClick={() => removeFile(fileIndex)} disabled={updateTicketLoading}>
                                <DeleteIcon />
                              </button>
                            </div>
                          ))}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {validations.attachment && <p className="page_error">{validations.attachment}</p>}
                <div className="create-ticket-btns">
                  <button disabled={updateTicketLoading} className="btn-restart" onClick={restartProcess}>
                    {t('Tickets.restartProcess')}
                  </button>
                  <Button
                    disabled={updateTicketLoading}
                    borderOnly
                    onClick={handleSubmit}
                    additionalClassName="btn-submit">
                    {updateTicketLoading ? t('personalData.loading') : t('Tickets.submit')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <CancelCreateTicketModal
        onClose={() => setCancelModalOpen(false)}
        open={cancelModalOpen}
        closeNewTicket={closeNewTicket}
      />
    </>
  );
}
