/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { WidthProvider, Responsive, getBreakpointFromWidth } from 'react-grid-layout';
import { Container } from '../container';
import Loader from 'src/components/Loader';
// api
import { useDispatch, useSelector } from 'src/store';
import { getWidgetInfo } from 'src/slices/users';
import useAuth from 'src/hooks/useAuth';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { calcSize, calculateItemPositions, getBreakpoint } from 'src/utils/widget';
import { GetWidget } from '../widget';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

let totalWidthOfItems = 0;
const changeTotalWidthOfItems = data => {
  totalWidthOfItems += data;
};
const generateLayout = (item, index, totalWidthOfItems, changeTotalWidthOfItems, itemPositions) => {
  const { size, x, y } = item;
  changeTotalWidthOfItems(calcSize(size, item).w);
  const layoutRenerated = {
    i: item.i || index + '',
    x: itemPositions[index] !== null ? itemPositions[index].x : item.x != null ? item.x : totalWidthOfItems % 12,
    y:
      itemPositions[index] !== null
        ? itemPositions[index].y
        : item.y != null
        ? item.y
        : Math.floor(totalWidthOfItems / 12),
    ...calcSize(size, item)
  };
  return layoutRenerated;
};

const defaultProps = {
  compactType: `vertical`,
  className: `widgetlayout`,
  rowHeight: 72,
  breakpoints: { xxl: 1784, xl: 1384, lg: 968, md: 712, sm: 520, xs: 0 },
  cols: { xxxl: 12, xxl: 12, xl: 12, lg: 12, md: 2, sm: 1, xs: 1 },
  margin: [32, 32],
  containerPadding: [0, 0],
  isDraggable: false,
  isResizable: false
};

const WidgetGrid = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { widgetInfo } = useSelector(state => state.users);

  const [openDialog, setOpenDialog] = useState(false);

  const [globalLayouts, setLayouts] = useState({});
  const [itemPositions, setItemPositions] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [currentBreakPoint, setCurrentBreakPoint] = useState(null);

  const lang = useRef(
    (() => {
      const Storage = window.localStorage;
      return Storage?.i18nextLng || 'en';
    })()
  );

  useEffect(() => {
    if (user && widgetInfo && widgetInfo.length === 0) {
      dispatch(getWidgetInfo(lang.current));
    }
  }, [user, widgetInfo]);

  useEffect(() => {
    if (widgets && widgets.length === 0) {
      if (widgetInfo?.data) {
        setWidgets([
          {
            type: 'tickets',
            title: t('Tickets.yourSuportServices'),
            size: 'xwide',
            isNew: 0,
            w: 8,
            minW: null,
            maxW: null,
            h: ['md', 'sm', 'xs'].includes(getBreakpoint()) ? 5 : 4,
            minH: null,
            maxH: null
          },
          ...widgetInfo.data
        ]);
      }
    }
  }, [widgetInfo]);

  useEffect(() => {
    if (widgets.length) {
      setItemPositions(calculateItemPositions(widgets, totalWidthOfItems));
    }
  }, [widgets.length, currentBreakPoint]);

  useEffect(() => {
    if (widgets.length && itemPositions.length) {
      const layoutArr = [];
      const newWigets = [];
      widgets.forEach((item, index) => {
        const dataGrid = generateLayout(item, index, totalWidthOfItems, changeTotalWidthOfItems, itemPositions);
        layoutArr.push(dataGrid);
        newWigets.push({
          ...item,
          i: dataGrid.i,
          x: dataGrid.x,
          y: dataGrid.y,
          w: dataGrid.w,
          minW: dataGrid.minW,
          maxW: dataGrid.maxW,
          h: dataGrid.h,
          minH: dataGrid.minH,
          maxH: dataGrid.maxH
        });
      });
      setWidgets(newWigets);
      if (currentBreakPoint) setLayouts({ ...globalLayouts, [currentBreakPoint]: layoutArr });
      else setLayouts({ ...globalLayouts, [Object.keys(globalLayouts)[0]]: layoutArr });
    }
  }, [widgets.length, currentBreakPoint, itemPositions]);

  const onLayoutChange = (currentLayout, allLayouts) => {
    // if (Object.keys(globalLayouts).length === 0)
    setLayouts(allLayouts);
  };
  const onBreakpointChange = (newBreakpoint, newCols) => {
    setCurrentBreakPoint(newBreakpoint);
  };
  return (
    <Container>
      <div className="dashboard-widgets-outer">
        {widgets.length > 0 ? (
          <div className="dashboard-widgets">
            <ResponsiveReactGridLayout
              {...defaultProps}
              layouts={globalLayouts}
              onLayoutChange={onLayoutChange}
              onBreakpointChange={onBreakpointChange}>
              {widgets.map((item, index, items) => {
                return (
                  <div key={index} data-size={item.size}>
                    <GetWidget data={item} />
                  </div>
                );
              })}
            </ResponsiveReactGridLayout>
          </div>
        ) : (
          <Loader loading={true} />
        )}
      </div>
    </Container>
  );
};

export { WidgetGrid };
